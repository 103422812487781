import { AppSidebar } from 'components/app-sidebar';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from 'components/ui/breadcrumb';
import { SidebarInset, SidebarProvider, SidebarSeparator, SidebarTrigger } from 'components/ui/sidebar';
import { BugPlay, SquareTerminal } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { setDebugger } from 'redux/slices/debuggerSlice';
import { ThemeContext } from 'contexts/ThemeContext';
import Debugger from 'features/debugger';
import DowngradeModal from 'components/modal/DowngradeModal';
import { projectListViewStates } from 'constants';
import { setLoginState, setStripeInfo, setUserRole } from 'redux/slices/userSlice';
import { planMap } from 'constants/routesWithRoles';
import { fetchPolicyData } from 'features/settings/ExecutionPolicies/PolicyList/io';
import Loading from 'components/loading/Loading';
import { StripePlanStatusGuard } from 'components/StripePlanStatusGuard';
import { StepsProvider } from 'react-step-builder';
import OnboardModal from 'components/modal/OnboardModal';
import { useToast } from 'hooks/use-toast';

export const ProfileLayout = () => {
  const [viewState, setViewState] = useState(projectListViewStates.LOADING);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [execPolicies, setExecPolicies] = useState([]);
  const [showDowngradeModal, setShowDowngradeModal] = useState(false);
  const location = useLocation();
  const userState = useSelector((state) => state.userState);
  const { toast } = useToast();
  const { success, payload, successStripeInfo, payloadStripeInfo } = useLoaderData();
  const debuggerState = useSelector((state) => state.debuggerState.debugger);

  useEffect(() => {
    if (!success) {
      setViewState(projectListViewStates.ERROR);
      dispatch(setLoginState(false));
      return navigate('/', { replace: true });
    } else {
      dispatch(setUserRole(payload));
      dispatch(setLoginState(true));
      dispatch(setStripeInfo(payloadStripeInfo));
      setViewState(projectListViewStates.DONE);
    }
  }, [navigate]);

  useEffect(() => {
    if (successStripeInfo) {
      if (
        payloadStripeInfo.previousSubscriptionPlan !== '' &&
        planMap.get(payloadStripeInfo.subscriptionPlan) <
          planMap.get(payloadStripeInfo.previousSubscriptionPlan)
      ) {
        fetchPolicyData().then(
          ({ success, payload }) => {
            void success;
            const { policies } = payload;
            setExecPolicies(policies);
            setShowDowngradeModal(true);
          },
          (err) => {
            console.log('error', err);
          }
        );
      } else {
        setShowDowngradeModal(false);
      }
    }
  }, [successStripeInfo]);

  if (viewState === projectListViewStates.LOADING) {
    return <Loading />;
  }

  if (viewState === projectListViewStates.ERROR) {
    return toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
  }
  const handleDebugger = () => {
    dispatch(setDebugger(!debuggerState));
  };
  return (
    <StripePlanStatusGuard>
    <SidebarProvider>
      <AppSidebar />
      <SidebarInset>
        <header className="group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12 flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear">
          <div className="flex w-full  items-center justify-between gap-2 px-4">
            <div className="flex items-center gap-2">
              <SidebarTrigger className="-ml-1" />
              <SidebarSeparator orientation="vertical" className="mr-2 h-4" />
              <Breadcrumb>
                <BreadcrumbList>
                  <BreadcrumbSeparator className="hidden md:block" />
                  <BreadcrumbItem className="hidden md:flex">
                    {location.pathname
                      .split('/')
                      .filter(Boolean)
                      .map((part, index, arr) => (
                        <React.Fragment key={index}>
                          <BreadcrumbPage>
                            {(index < 2 ? part.replace(/-/g, ' ') : part)
                              .charAt(0)
                              .toUpperCase() +
                              (index < 2 ? part.replace(/-/g, ' ') : part).slice(1)}
                          </BreadcrumbPage>
                          {index < arr.length - 1 && (
                            <BreadcrumbSeparator className="hidden md:block" />
                          )}
                        </React.Fragment>
                      ))}
                  </BreadcrumbItem>
                </BreadcrumbList>
              </Breadcrumb>
            </div>
            <div className="flex items-center gap-2">
              <Link to="/execution-command">
                <SquareTerminal />
              </Link>
              <div onClick={handleDebugger}>
                <BugPlay />
              </div>
            </div>
          </div>
        </header>
        <div className="flex flex-1 flex-col gap-4 p-4 pt-0">
          <ThemeContext.Provider>
            <Outlet />
          </ThemeContext.Provider>
          {userState.userRole.role === 'superadmin' && (
            <StepsProvider>
              <OnboardModal />
            </StepsProvider>
          )}
        </div>
        <DowngradeModal execPolicies={execPolicies} showDowngradeModal={showDowngradeModal} />
        {debuggerState === true && <Debugger />}
      </SidebarInset>
    </SidebarProvider>
  </StripePlanStatusGuard>
  );
};
